import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import styles from './DurationBar.module.less';

class DurationBar extends PureComponent {
  constructor(props) {
    super(props);
  }

  static propTypes = {
    onProgressMouseDown: PropTypes.func.isRequired,
    onProgressMouseUp: PropTypes.func.isRequired,
    onValueChange: PropTypes.func.isRequired,
    progress: PropTypes.string.isRequired,
  };

  static defaultProps = {};

  handleValueChange = ({ target: { value }}) => this.props.onValueChange(value);

  render() {
    const { onProgressMouseDown, onProgressMouseUp, progress } = this.props;

    return (
      <div className={styles.durationBar}>
        <input
          className={styles.durationBar__slider}
          max={1}
          min={0}
          onChange={this.handleValueChange}
          onMouseDown={onProgressMouseDown}
          onMouseUp={onProgressMouseUp}
          step="any"
          type="range"
          value={progress}
        />
        <progress
          className={styles.durationBar__progress}
          max={1}
          value={progress}
        />
      </div>
    );
  }
}

export default DurationBar;
