import React, { createRef, PureComponent } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Waypoint } from 'react-waypoint';
import MediaCaption from '../../MediaCaption';
import ActionButton from './ActionButton';
import Info from './Info';
import styles from './VideoType.module.less';

class VideoType extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      currentTime: '0:00',
      isFullScreen: false,
      isHovered: false,
      isInitiated: false,
      isPlaying: false,
      progress: '0',
    };
    this.player = createRef();
    this.wrapper = createRef();
    this.timer;
  }

  static propTypes = {
    controls: PropTypes.bool,
    data: PropTypes.object.isRequired,
  };

  handleActionClick = () => {
    const { current: player } = this.player;

    this.setState({
      isHovered: this.state.isPlaying,
      isInitiated: true,
      isPlaying: !this.state.isPlaying,
    }, () => {
      if (this.state.isPlaying) {
        player.play();
      } else {
        player.pause();
      }
    });
  };

  handleHover = () => {
    clearTimeout(this.timer);
    this.setState({
      isHovered: !this.state.isHovered,
    });
  };

  handleFullScreenClick = () => {
    this.setState({
      isFullScreen: !this.state.isFullScreen,
    }, () => {
      if (this.state.isFullScreen) {
        const element = this.wrapper.current;

        if (element.requestFullscreen) {
          element.requestFullscreen();
        } else {
          if (element.mozRequestFullScreen) {
            element.mozRequestFullScreen();
          } else {
            if (element.webkitRequestFullscreen) {
              element.webkitRequestFullscreen(Element.ALLOW_KEYBOARD_INPUT);
            }
          }
        }
      } else {
        if (document.cancelFullScreen) {
          document.cancelFullScreen();
        } else {
          if (document.mozCancelFullScreen) {
            document.mozCancelFullScreen();
          } else {
            if (document.webkitCancelFullScreen) {
              document.webkitCancelFullScreen();
            }
          }
        }
      }
    });
  };

  handleMoveDetection = () => {
    if (this.state.isInitiated) {
      clearTimeout(this.timer);

      this.setState({
        isHovered: true,
      }, () => {
        this.timer = setTimeout(() => {
          this.setState({
            isHovered: false,
          });
        }, 3000)
      });
    }
  };

  handleProgressMouseDown = () => {
    this.setState({
      isPlaying: false,
    }, () => {
      this.player.current.pause();
    });
  };

  handleProgressMouseUp = () => {
    this.setState({
      isPlaying: true,
    }, () => {
      this.player.current.play();
    });
  };

  handleValueChange = (value) => {
    const floatValue = parseFloat(value);
    const currentTime = this.player.current.duration * floatValue;

    this.setState({
      currentTime: this.getTime(currentTime),
      progress: floatValue.toFixed(2),
    }, () => {
      this.player.current.currentTime = currentTime;
    });
  };

  handleVideoPlaying = () => {
    this.setState({
      currentTime: this.getTime(this.player.current.currentTime),
      progress: this.getProgressValue(this.player.current.currentTime, this.player.current.duration),
    });
  };

  getTime = (time) => {
    const correctTime = Math.floor(time);
    const hours = Math.floor(correctTime / 3600);
    const minutes = Math.floor((correctTime % 3600) / 60);
    const seconds = `0${correctTime % 60}`.slice(-2);

    if (!hours) {
      return `${minutes}:${seconds}`;
    }

    return `${hours}:${minutes}:${seconds}`;
  };

  getProgressValue = (currentTime, duration) => (currentTime / duration).toFixed(2);

  render() {
    const { data: { primary: data } } = this.props;
    const { isHovered, isInitiated, isPlaying, progress } = this.state;

    return (
      <Waypoint
        onEnter={this.handleActionClick}
        onLeave={this.handleActionClick}>
        <figure className={styles.videoType}>
          <div
            className={classnames(styles.videoType__player, {
              [styles.videoType__playerIsOverlayVisible]: (isInitiated && !isPlaying) || isHovered,
            })}
            onClick={this.handleActionClick}
            onMouseEnter={this.handleHover}
            onMouseLeave={this.handleHover}
            onMouseMove={this.handleMoveDetection}
            ref={this.wrapper}>
            <video
              className={styles.videoType__media}
              controls={false}
              loop
              onTimeUpdate={this.handleVideoPlaying}
              ref={this.player}
              width="100%"
              muted>
              <source src={data.video_link.url} type="video/mp4" />
            </video>
            <ActionButton
              isInitiated={isInitiated}
              isHovered={isHovered}
              isPlaying={isPlaying}
              onClick={this.handleActionClick}
            />
            {isInitiated && (
              <Info
                currentTime={this.state.currentTime}
                duration={this.getTime(this.player.current.duration)}
                isHovered={isHovered}
                onFullScreen={this.handleFullScreenClick}
                onProgressMouseDown={this.handleProgressMouseDown}
                onProgressMouseUp={this.handleProgressMouseUp}
                onValueChange={this.handleValueChange}
                progress={progress}
              />
            )}
          </div>
          {data.video_description.text && (
            <MediaCaption content={data.video_description.text} />
          )}
        </figure>
      </Waypoint>
    );
  }
}

export default VideoType;
