import React, { createRef, Fragment, PureComponent } from 'react';
import PropTypes from 'prop-types';
import parse from 'html-react-parser';
import { getPageSectionId } from '../../../utils/getPageSectionId';
import parseContentType from '../../../utils/parseContentType';
import styles from '../Content.module.less';

class ContentType extends PureComponent {
  constructor(props) {
    super(props);
    this.subtitle = props.data.primary.content_title.text && createRef();
    this.hasSubtitle = props.data.primary.content_title.text;
  }

  static propTypes = {
    data: PropTypes.object.isRequired,
  };

  componentDidMount() {
    if (this.hasSubtitle) {
      this.props.onSubtitleInit(this.subtitle, 'subtitle');
    }
  }

  render() {
    const { data } = this.props;
    const title = data.primary.content_title.text
    const htmlContent = data.primary.content.html;

    return (
      <Fragment>
        {title && (
          <h2
            className={styles.documentationContent__subtitle}
            id={getPageSectionId(title)}
            ref={this.subtitle}>
            {title}
          </h2>
        )}
        {htmlContent && (
          parse(parseContentType(htmlContent))
        )}
      </Fragment>
    );
  };
}

export default ContentType;
