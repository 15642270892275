import ENV from '../enums/envs';

const shouldTrackAnalytics = process.env.NODE_ENV === ENV.PRODUCTION;

export function isMetricsIncluded() {
  return !(typeof _kmq === 'undefined');
}

export function kissEvent(event, props = {}, callback) {
  return new Promise(() => {
    if (!navigator.onLine) {
      return;
    }

    if (!isMetricsIncluded()) {
      return setTimeout(() => kissEvent(event, props), 1000);
    }

    if (!shouldTrackAnalytics) {
      console.log('kissMetricsEvent', event, props);
    }

    try {
      _kmq.push(['record', event, props, callback]);

      if (callback) {
        callback();
      }
    } catch (error) {
      if (process.env.NODE_ENV !== ENV.PRODUCTION) {
        console.error('Error Tracking KissMetrics Event', error);
      }
    }
  });
}

export function sendVirtualPageView({ title, url }) {
  const data = {
    event: 'virtualPageView',
    virtualPageURL: url,
    virtualPageTitle: title,
  };

  if (!window.dataLayer) {
    window.dataLayer = [];
  }

  if (!shouldTrackAnalytics) {
    console.log('GTM dataLayer', data);
  }

  window.dataLayer.push(data);

  // GA4 tracking
  gtmTrack({
    event: 'page_viewed',
    page_title: title,
    page_url: url,
  });
}

function getCookieValue(cookieName) {
  const cookieString = document.cookie;
  const cookies = cookieString.split(';');

  for (let i = 0; i < cookies.length; i++) {
    const cookie = cookies[i].trim();
    if (cookie.startsWith(`${cookieName}=`)) {
      return cookie.substring(cookieName.length + 1);
    }
  }

  return null; // Cookie not found
}

function parseUserIdCookie() {
  const GTM_UXPIN_USER_ID = 'gtmUXPinUserId';
  // gtmUXPinUserId is created in the mixpanel/properties_controller in uxpin-rails
  // it's necessary since we have no access to id_dms_user in uxpin-docs
  const userId = getCookieValue(GTM_UXPIN_USER_ID);

  return userId ? parseInt(userId, 10) : null;
}

function getPageLanguage() {
  // match rewview / staging / prod urls that contains /kr /jp or /kr/ /jp/
  const matches = window.location.href.match(/\/(jp|kr)\/?|\/(jp|kr)\b/);

  if (matches && matches[1]) {
    switch (matches[1]) {
      case 'kr':
        return 'ko-KR';
      case 'jp':
        return 'ja-JP';
      default:
        return 'en-US';
    }
  }

  return 'en-US';
}

function gtmTrack(eventPayload) {
  const dataLayer = window.dataLayer || [];
  dataLayer.push({
    page_language: getPageLanguage(),
    page_title: document.title,
    page_url: window.location.href,
    user_id: parseUserIdCookie(),
    ...eventPayload,
  });
}
