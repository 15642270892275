import React from 'react';
import PropTypes from 'prop-types';
import styles from './MediaCaption.module.less';

const MediaCaption = ({ content }) => (
  <figcaption className={styles.mediaCaption}>
    <p className={styles.mediaCaption__content}>{content}</p>
  </figcaption>
);

MediaCaption.propTypes = {
  content: PropTypes.string.isRequired,
};

export default MediaCaption;
