import React, { createRef, PureComponent } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import styles from './Field.module.less';

class Field extends PureComponent {
  static propTypes = {
    isSingleView: PropTypes.bool.isRequired,
    onChange: PropTypes.func.isRequired,
  };

  inputElement = createRef();

  handleBlur = () => {
    if (this.inputElement) {
      this.inputElement.current.focus();
    }
  };

  render() {
    const { isSingleView, onChange } = this.props;

    return (
      <input
        autoFocus
        className={classnames(styles.field, { [styles.fieldIsSingleView]: isSingleView })}
        onBlur={this.handleBlur}
        onChange={onChange}
        placeholder="Search for topic or feature…"
        ref={this.inputElement}
        type="search"
      />
    );
  };
}

export default Field;
