import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import styles from './Container.module.less';

const Container = ({ children, inlineStyle, modifierClassname, verticalCenterStyle }) => (
  <div className={classnames(styles.container, modifierClassname, {
    [styles.containerInlineStyle]: inlineStyle,
    [styles.containerVerticalCenterStyle]: verticalCenterStyle,
  })}>
    {children}
  </div>
);

Container.propTypes = {
  children: PropTypes.node.isRequired,
  inlineStyle: PropTypes.bool,
  modifierClassname: PropTypes.string,
  verticalCenterStyle: PropTypes.bool,
};

Container.defaultProps = {
  inlineStyle: false,
  modifierClassname: null,
  verticalCenterStyle: false,
};

export default Container;
