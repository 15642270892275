const UXPIN_LINK = 'https://www.uxpin.com';

const BASE_URL = 'docs';

const SLICES = {
  CODE_TYPE: 'code',
  CONTENT: 'content',
  IMAGE: 'image_with_caption',
  LINK_LIST: 'link_list',
  TABLE: 'table',
  TIP: 'tip',
  VIDEO: 'video',
  QUOTE: 'quote',
};

const TIP_TYPES = {
  INFO: 'Info tip',
  POWER: 'Power tip',
  SMALL_INFO: 'Info tip mini size',
};

const PAGINATION_TYPES = {
  NEXT: 'next',
  PREVIOUS: 'previous',
};

const KEY_NAMES = {
  ALT: 'alt',
  CMD: 'cmd',
  CONTROL: 'ctrl',
  OPTION: 'option',
  SHIFT: 'shift',
};

const KEY_NAMES_TO_CLASS = {
  [KEY_NAMES.ALT]: KEY_NAMES.OPTION,
  [KEY_NAMES.CONTROL]: KEY_NAMES.CMD,
};

export {
  BASE_URL,
  KEY_NAMES,
  KEY_NAMES_TO_CLASS,
  PAGINATION_TYPES,
  SLICES,
  TIP_TYPES,
  UXPIN_LINK,
};
