import React from 'react';
import PropTypes from 'prop-types';
import styles from './Time.module.less';

const Time = ({ currentTime, duration }) => (
  <span className={styles.time}>
    {currentTime} / {duration}
  </span>
);

Time.propTypes = {
  currentTime: PropTypes.string.isRequired,
  duration: PropTypes.string.isRequired,
};

export default Time;
