import React, { Fragment } from 'react';
import { kebabCase } from 'lodash';
import { StaticQuery, graphql } from 'gatsby';
import MediaQuery from 'react-responsive';
import classnames from 'classnames';
import NavItem from './NavItem';
import BREAKPOINTS from '../../enums/breakpoints';
import styles from './Nav.module.less';
import NavSwitcher from "./NavSwitcher";

const Nav = ({ data: { items }, isOpen, onMobileNavOpen }) => (
  <Fragment>
    <MediaQuery maxWidth={BREAKPOINTS.PHONE}>
      <NavSwitcher isActive={isOpen} onMobileNavOpen={onMobileNavOpen} />
    </MediaQuery>
    <nav className={classnames(styles.nav, {
      [styles.navIsOpen]: isOpen,
    })}>
      <ul className={classnames(styles.nav__list, {
        [styles.nav__listIsOpen]: isOpen,
      })}>
        {items.map(({ item: { document } }) => {
          const { dropdown_elements, link, name } = document[0].data;

          return (
            <NavItem
              dropdownItems={dropdown_elements}
              href={link && link.url}
              key={kebabCase(name)}>
              {name}
            </NavItem>
          );
        })}
        <NavItem alignToRight href='https://app.uxpin.com/'>Login</NavItem>
      </ul>
    </nav>
  </Fragment>
);

export default (props) => (
  <StaticQuery
    query={graphql`
      {
        allPrismicTopbarNav {
          edges {
            node {
              data {
                items {
                  item {
                    document {
                      data {
                        name,
                        link {
                          url
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    `}
    render={(data) => (
      <Nav {...props} data={data.allPrismicTopbarNav.edges[0].node.data} />
    )}
  />
);
