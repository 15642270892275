import React from 'react';
import PropTypes from 'prop-types';
import Result from './Result';
import NoResults from './NoResults';
import { BASE_URL } from '../../enums/enums';
import styles from './Results.module.less';

const getResultHref = ({ categoryUid, pageUid, titleUid }) => {
  let url = `/${BASE_URL}/${categoryUid}/${pageUid}`;

  if (titleUid) {
    url += `/#${titleUid}`
  }

  return url;
};

const Results = ({ activePage, data, isFieldContainingText, onResultClick }) => {
  if (!data.length) {
    if (isFieldContainingText) {
      return (
        <section className={styles.results}>
          <NoResults />
        </section>
      );
    }

    return null;
  }

  return (
    <ul className={styles.results}>
      {data.map(({ item: {
        category,
        categoryUid,
        content,
        page,
        pageUid,
        title,
        titleUid
      }}) => {
        const resultHref = getResultHref({ categoryUid, pageUid, titleUid });

        return (
          <Result
            activePage={activePage}
            categoryName={category}
            categoryUid={categoryUid}
            content={content}
            href={resultHref}
            key={resultHref}
            onClick={onResultClick}
            sectionTitle={title}
            pageTitle={page}
            pageUid={pageUid}
            titleUid={titleUid}
          />
        );
      })}
    </ul>
  );
};

Results.propTypes = {
  activePage: PropTypes.string.isRequired,
  data: PropTypes.array.isRequired,
  isFieldContainingText: PropTypes.bool.isRequired,
  onResultClick: PropTypes.func.isRequired,
};

export default Results;
