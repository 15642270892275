import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import FullScreenButton from "./FullScreenButton";
import Time from './Time';
import DurationBar from "./DurationBar";
import styles from './Info.module.less';

const Info = ({
  currentTime,
  duration,
  isHovered,
  onFullScreen,
  onProgressMouseDown,
  onProgressMouseUp,
  onValueChange,
  progress
}) => {
  return (
    <div
      className={classnames(styles.durationBar, {
        [styles.durationBarIsHovered]: isHovered,
      })}
      onClick={(event) => event.stopPropagation()}>
      <DurationBar
        onProgressMouseDown={onProgressMouseDown}
        onProgressMouseUp={onProgressMouseUp}
        onValueChange={onValueChange}
        progress={progress}
      />
      <Time currentTime={currentTime} duration={duration} />
      <FullScreenButton onClick={onFullScreen} />
    </div>
  );
};

Info.propTypes = {
  currentTime: PropTypes.string.isRequired,
  duration: PropTypes.string.isRequired,
  isHovered: PropTypes.bool.isRequired,
  onFullScreen: PropTypes.func.isRequired,
  onProgressMouseDown: PropTypes.func.isRequired,
  onProgressMouseUp: PropTypes.func.isRequired,
  onValueChange: PropTypes.func.isRequired,
  progress: PropTypes.string.isRequired,
};

export default Info;
