import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';

const Facebook = ({ description, id, imageUrl, pageUrl, title }) => (
  <Helmet>
    <meta content={title} property='og:title' />
    <meta content={description} property='og:description' />
    <meta content={pageUrl} property="og:url" />
    <meta content={imageUrl} property="og:image" />
    <meta content="1200" property="og:image:width" />
    <meta content="630" property="og:image:height" />
    <meta content="article" property="og:type" />
    <meta content={id} property="fb:app_id" />
  </Helmet>
);

Facebook.propTypes = {
  description: PropTypes.string.isRequired,
  imageUrl: PropTypes.string.isRequired,
  pageUrl: PropTypes.string,
  title: PropTypes.string.isRequired,
};

Facebook.defaultProps = {
  pageUrl: '',
};

export default Facebook;
