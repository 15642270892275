import React, { PureComponent } from 'react';
import classnames from 'classnames';
import { isIntercomAvailable } from "../../utils/intercomChat";
import styles from './Menu.module.less';

const ITEMS_TYPE = {
  DEFAULT: 'Default',
  NEW: 'New',
  CONTACT: 'Contact',
};

class Menu extends PureComponent {
  state = {
    isOpen: this.props.isOpen,
  }

  handleClick = () => {
    this.setState((state) => ({
      isOpen: !state.isOpen,
    }));
  }

  handleIntercomInteraction = (e) => {
    if (isIntercomAvailable() && e) {
      e.preventDefault();
    }
  }

  render() {
    const { data: { items, name } } = this.props;

    return (
      <div className={styles.menu}>
        <strong
          className={styles.menu__title}
          onClick={this.handleClick}>
          {name}
        </strong>
        <ul className={classnames(styles.menu__list, {
          [styles.menu__listIsOpen]: this.state.isOpen,
        })}>
          {items.map(({ link: { target, url }, type, name }) => (
            <li className={styles.menu__item} key={name}>
              <a
                className={styles.menu__link}
                href={url}
                onClick={type === ITEMS_TYPE.CONTACT ? this.handleIntercomInteraction : null}
                target={target || '_self'}>
                {name}
              </a>
              {type === ITEMS_TYPE.NEW && <span className={styles.menu__badge}>New</span>}
            </li>
          ))}
        </ul>
      </div>
    );
  }
}

export default Menu;
