import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import styles from './NavItem.module.less';

class NavItem extends PureComponent {
  static propTypes = {
    alignToRight: PropTypes.bool,
    children: PropTypes.node.isRequired,
    href: PropTypes.string,
  };

  static defaultProps = {
    alignToRight: false,
    href: '#',
  };

  render() {
    const { alignToRight, children, href } = this.props;

    return (
      <li className={classnames(styles.navItem, {
        [styles.navItemAlignToRightStyle]: alignToRight,
      })}>
        <a
          className={styles.navItem__link}
          href={href}>
          {children}
        </a>
      </li>
    );
  }
}

export default NavItem;
