const { get, isEmpty } = require('lodash');
const { getPageSectionId } = require('./getPageSectionId');
const { cleanUpContentFromSpecialSigns } = require('./cleanUpContentFromSpecialSigns');
const { SLICE_TYPES } = require('../enums/sliceTypes');

function isNextItemWithTitle(item) {
  const title = get(getItemData(item), 'title');

  return !!title;
}

function getTableContent(items) {
  let content = '';

  items.forEach((item) => {
    const col1 = get(item, 'col1.text', '');
    const col2 = get(item, 'col2.text', '');

    if (content) {
      content += ', ';
    }

    if (col2) {
      content += `${col1}: ${col2}`;
    } else {
      content += `${col1}`;
    }
  });

  return content;
}

function getLinkList(items) {
  let content = '';

  items.forEach((item) => {
    const{ name } = item;

    if (content) {
      content += ', ';
    }

    content += name;
  });

  return content;
}

function getTipContent(title, content) {
  if (!title) {
    return content;
  }

  return `${title}: ${content}`;
}

function getItemData(item) {
  const { slice_type: type } = item;

  switch (type) {
    case SLICE_TYPES.TIP:
      return {
        content: getTipContent(item.primary.tip_title, item.primary.tip.text),
        title: null,
      };
    case SLICE_TYPES.TABLE:
      const tableContent = getTableContent(item.items);

      return {
        content: tableContent,
        title: null,
      };
    case SLICE_TYPES.LINK_LIST:
      const linkListContent = getLinkList(item.items);

      return {
        content: linkListContent,
        title: null,
      };
    default:
      return {
        content: item.primary.content.text,
        title: item.primary.content_title ? item.primary.content_title.text : null,
      };
  }
}

function getCleanedUpData(data) {
  return data.filter((item) => !isEmpty(item) && !!item.slice_type);
}

function getSearchData(data) {
  const categories = get(data, 'allPrismicDocs.edges[0].node.data.categories', []);
  const result = [];

  categories.forEach((category) => {
    const categoryData = get(category, 'category.document[0].data', {});
    const categoryName = get(categoryData, 'name.text');
    const { pages } = categoryData;
    const { category: { uid: categoryUid } } = category;

    if (!categoryData) {
      return;
    }

    pages.forEach((page) => {
      const pageUid = get(page, 'page.uid');
      const pageData = get(page, 'page.document[0].data', {});
      const pageName = get(pageData, 'title.text');
      const { body, visible_in_table_of_content: isVisible } = pageData;
      const shouldBeIgnoredFromSearchResults = isVisible === 'false';
      const filteredData = getCleanedUpData(body);

      if (shouldBeIgnoredFromSearchResults) {
        return;
      }

      const dataContainer = {
        content: null,
        title: null,
      };

      filteredData.forEach((filteredItem, index) => {
        const { title, content } = getItemData(filteredItem);
        const nextItem = filteredData[index + 1];
        const shouldMixContentWithNextItemContent = nextItem && !isNextItemWithTitle(nextItem);

        if (!dataContainer.content) {
          dataContainer.content = content;
        }

        if (!title && !dataContainer.title) {
          dataContainer.title = pageName;
        } else if (title) {
          dataContainer.title = title;
        }

        if (shouldMixContentWithNextItemContent) {
          dataContainer.content = `${dataContainer.content} ${getItemData(nextItem).content}`;
        } else {
          result.push({
            category: categoryName,
            categoryUid,
            content: dataContainer.content ? cleanUpContentFromSpecialSigns(dataContainer.content) : '',
            page: pageName,
            pageUid,
            title: dataContainer.title,
            titleUid: pageName !== dataContainer.title ? getPageSectionId(dataContainer.title) : null,
          });

          dataContainer.content = null;
          dataContainer.title = null;
        }
      });
    });
  });

  return result;
}

module.exports = {
  getSearchData,
};
