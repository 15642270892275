import React from 'react';
import PropTypes from 'prop-types';
import FullScreen from '../../../../assets/player-full-screen.inline.svg';
import styles from './FullScreenButton.module.less';

const FullScreenButton = ({ onClick }) => (
  <button
    className={styles.fullScreenButton}
    onClick={onClick}
    type="submit">
    <FullScreen
      className={styles.fullScreenButton__icon}
      width={14}
    />
    <span className={styles.fullScreenButton__text}>Full screen</span>
  </button>
);

FullScreenButton.propTypes = {
  onClick: PropTypes.func.isRequired,
};

export default FullScreenButton;
