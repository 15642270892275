import React from 'react';
import PropTypes from 'prop-types';
import parse from 'html-react-parser';
import styles from './QuoteType.module.less';

const QuoteType = ({ data }) => {
  return (
    <blockquote className={styles.quoteType}>
      {parse(data.primary.quote.html)}
      {data.primary.name_of_the_author.html && (
        <footer className={styles.quoteType__author}>{parse(data.primary.name_of_the_author.html)}</footer>
      )}
    </blockquote>
  );
};

QuoteType.propTypes = {
  data: PropTypes.object.isRequired,
};

export default QuoteType;
