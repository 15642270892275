import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import classnames from 'classnames';
import { camelCase, get } from 'lodash';
import ArrowNext from '../../assets/pagination-arrow-right.inline.svg';
import ArrowPrevious from '../../assets/pagination-arrow-left.inline.svg';
import {BASE_URL, PAGINATION_TYPES} from '../../enums/enums';
import styles from './PaginationLink.module.less';

const PaginationLink = ({ children, link, type }) => {
  const iconByType = {
    [PAGINATION_TYPES.NEXT]: ArrowNext,
    [PAGINATION_TYPES.PREVIOUS]: ArrowPrevious,
  };
  const linkClassnames = classnames(
    styles.paginationLink,
    styles[camelCase(`pagination-link--${type}-type`)],
    {
      [styles.paginationLinkGrayStyle]: type === PAGINATION_TYPES.PREVIOUS,
    }
  );
  const iconClassname = classnames(
    styles.paginationLink__illustration,
    styles[`paginationLink__${camelCase(`illustration--${type}-type`)}`]
  );
  const Icon = get(iconByType, type);

  return (
    <Link
      className={linkClassnames}
      to={`${BASE_URL}/${link}`}>
      {children}
      <Icon className={iconClassname} />
    </Link>
  );
};

PaginationLink.propTypes = {
  children: PropTypes.node.isRequired,
  link: PropTypes.string.isRequired,
  type: PropTypes.oneOf(Object.values(PAGINATION_TYPES)),
};

export default PaginationLink;
