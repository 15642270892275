import React from 'react';
import { kebabCase } from 'lodash';
import parse from 'html-react-parser';
import styles from './TableType.module.less';

const TableType = ({ data }) => (
  <table className={styles.tableType}>
    <thead className={styles.tableType__heading}>
      <tr>
        <td className={styles.tableType__title}>{data.primary.table_first_title.text}</td>
        <td className={styles.tableType__title}>{data.primary.table_second_title.text}</td>
      </tr>
    </thead>
    <tbody className={styles.tableType__body}>
      {data.items.map((row) => (
        <tr key={kebabCase(row.col1.text)}>
          <td className={styles.tableType__content}>{parse(row.col1.html)}</td>
          <td className={styles.tableType__content}>{parse(row.col2.html)}</td>
        </tr>
      ))}
      <tr></tr>
    </tbody>
  </table>
);

export default TableType;
