import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import MediaQuery from 'react-responsive';
import classnames from 'classnames';
import { get } from 'lodash';
import Container from './Container';
import Content from './Content/Content';
import TableOfContent from './TableOfContent/TableOfContent';
import BREAKPOINTS from '../enums/breakpoints';
import OptionList from './TableOfContent/OptionList';
import styles from './Documentation.module.less';

class Documentation extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      activeSectionId: typeof window !== 'undefined' ? window.location.hash.substr(1) : '',
    };

    this.anchorId = 0;
    this.anchors = [];
    this.prevWindowPosition = null;
    this.scrollDirection = null;
    this.isMobile = false;
  }

  static propTypes = {
    activeCategory: PropTypes.string.isRequired,
    activePage: PropTypes.string.isRequired,
    isSearchOpened: PropTypes.bool.isRequired,
    onSectionChange: PropTypes.func.isRequired,
    pageContent: PropTypes.shape({
      body: PropTypes.array,
      title: PropTypes.shape({
        text: PropTypes.string,
      }),
    }).isRequired,
    tableOfContentData: PropTypes.array.isRequired,
    subcategories: PropTypes.array.isRequired,
  };


  componentDidMount() {
    this.isMobile = window.innerWidth <= BREAKPOINTS.PHONE;

    if (!this.isMobile && this.props.subcategories.length) {
      window.addEventListener('scroll', this.handleScroll);
    }

    this.handleActiveSectionChange();
  }

  componentWillUnmount() {
    if (!this.isMobile && this.props.subcategories.length) {
      window.removeEventListener('scroll', this.handleScroll);
    }
  }

  componentDidUpdate() {
    const { activePage, onSectionChange, searchData } = this.props;

    if (searchData.pageId && searchData.sectionId && activePage === searchData.pageId) {
      this.handleActiveSectionChange(null, searchData.sectionId);
      onSectionChange();
    }
  }

  getElement = (ref, type) => {
    const element = {
      element: ref.current,
      position: parseInt(this.getElementData(ref.current).position, 10),
    };

    if (type !== 'video') {
      return this.anchors.push(element);
    }

    return this.videos.push(element);
  };

  getElementData = (element, offset = 0.39) => {
    const { top } = element.getBoundingClientRect();
    const position = parseInt(top - (window.innerHeight * offset), 10);

    return ({
      position,
    });
  };

  getScrollDirection(prevScrollY, scrollY) {
    if (prevScrollY === scrollY) {
      return this.scrollDirection;
    }

    return scrollY > prevScrollY ? 'down' : 'up';
  }

  updateSectionId = (anchorId) => {
    this.setState({
      activeSectionId: this.getElementId(this.anchors[anchorId]),
    }, () => {
      const { href, origin, pathname } = window.location;
      const { activeSectionId } = this.state;
      const hrefWithAnchor =`${origin}${pathname}#${activeSectionId}`;

      if (href === hrefWithAnchor) {
        return;
      }

      if (activeSectionId) {
        history.pushState(null, null, `#${activeSectionId}`);
      } else {
        history.pushState(null, null, ' ');
      }
    });
  };

  getElementId(node) {
    if (!node) {
      return null;
    }

    return node.element.id;
  }

  getId(id, list) {
    if (id < 0) {
      return 0;
    }

    if (id >= list.length) {
      return list.length - 1;
    }

    return id;
  }

  handleScroll = () => {
    const { element: anchor }  = this.anchors[this.getId(this.anchorId, this.anchors)];
    const { position: anchorPosition } = anchor && this.getElementData(anchor);
    this.scrollDirection = this.getScrollDirection(this.prevWindowPosition, window.pageYOffset);
    this.prevWindowPosition = window.scrollY;

    if (this.scrollDirection === 'down' && anchorPosition <= 0) {
      if (this.anchorId === this.anchors.length) {
        return;
      }

      this.updateSectionId(this.anchorId);
      this.anchorId += 1;
    }

    if (this.scrollDirection === 'up' && anchorPosition > 0) {
      if (!this.state.activeSectionId) {
        return;
      }

      this.anchorId -= 1;
      this.updateSectionId(this.anchorId);
    }
  };

  handleActiveSectionChange = (event, activeSectionId) => {
    const sectionId = activeSectionId || this.state.activeSectionId;

    if (event) {
      event.preventDefault();
    }

    if (!sectionId) {
      return;
    }

    const activeElement = this.anchors.find((anchor, index) => {
      const id = get(anchor, 'element.id');
      this.anchorId = index;

      return id === sectionId;
    }).element;

    this.updateSectionId(this.anchorId);
    activeElement.scrollIntoView();
    setTimeout(() => { // In some cases window is not scrolling in `componentDidMount`
      window.scroll(0, window.scrollY - 30);
    }, 0);
  };

  render() {
    const {
      activeCategory,
      activePage,
      isSearchOpened,
      pageContent,
      subcategories,
      tableOfContentData,
    } = this.props;

    return (
      <Container modifierClassname={classnames(styles.documentation, { [styles.documentationIsSearchActive]: isSearchOpened })}>
        <MediaQuery minWidth={BREAKPOINTS.TABLET_VERTICAL}>
          <TableOfContent
            activeCategory={activeCategory}
            activePage={activePage}
            activeSubcategory={this.state.activeSectionId}
            data={tableOfContentData}
            subcategories={subcategories}
            onActiveSectionChange={this.handleActiveSectionChange}
          />
        </MediaQuery>
        <MediaQuery maxWidth={BREAKPOINTS.PHONE}>
          <OptionList
            activeCategory={activeCategory}
            activePage={activePage}
            pages={tableOfContentData}
          />
        </MediaQuery>
        <Content
          data={pageContent}
          onSubtitleInit={this.getElement}
        />
      </Container>
    );
  }
}

export default Documentation;
