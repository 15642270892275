import { useEffect } from 'react';
import getMixpanel from '../utils/getMixpanel';

export default function useMixpanelTrackDownloads(articleRef) {
  function isLinkToDesktopApp(href) {
    return href.includes('UXPin-mac.dmg') || href.includes('UXPin-win.exe');
  }

  useEffect(() => {
    function clickHandler({ target }) {
      if (target.tagName === 'A' && isLinkToDesktopApp(target.href)) {
        getMixpanel((mixpanel) => {
          mixpanel.track('desktop_app_downloaded');
        });
      }
    }

    if (articleRef && articleRef.current) {
      articleRef.current.addEventListener('click', clickHandler);
    }

    return () => {
      if (articleRef && articleRef.current) {
        articleRef.current.removeEventListener('click', clickHandler);
      }
    };
  }, []);
}
