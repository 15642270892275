import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { UXPIN_LINK } from '../enums/enums';
import { LOGO_APPEARANCES, LOGO_FILES, LOGO_SIZE } from '../enums/logo';
import styles from './Logo.module.less';

const Logo = ({ appearance, modifierClassname }) => {
  return (
    <a href={`${UXPIN_LINK}/`} className={classnames(styles.logo, modifierClassname)}>
      <img
        className={styles.logo__pic}
        src={LOGO_FILES[appearance]}
        alt="UXPin logo"
        width={LOGO_SIZE}
      />
    </a>
  );
};

Logo.propTypes = {
  appearance: PropTypes.oneOf(Object.values(LOGO_APPEARANCES)),
  modifierClassname: PropTypes.string,
};

Logo.defaultProps = {
  appearance: LOGO_APPEARANCES.GRAY,
  modiferClassname: null,
};

export default Logo;
