function getFormatedDocsData(graphql) {
  if (graphql.data) {
    return graphql.data.allPrismicDocs.edges[0].node.data;
  }

  return graphql.allPrismicDocs.edges[0].node.data;
}

function getFormatedDocsPageData(document) {
  return document[0].data.pages;
}

function getFormatedDocsFirstPageData(document) {
  return document[0].data.pages[0];
}

function getPageName(document) {
  return document[0].data.title.text;
}

module.exports = {
  getFormatedDocsData,
  getFormatedDocsPageData,
  getFormatedDocsFirstPageData,
  getPageName,
};
