import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';

const HTML = ({ description, isPageExcluded, title }) => {
  const robotContent = isPageExcluded ? 'noindex, nofollow' : 'index, follow';

  return (
    <Helmet>
      <html lang="en" />
      <title>{title}</title>
      <meta content={description} name="description" />
      <meta content={robotContent} name="robots" />
    </Helmet>
  );
};

HTML.propTypes = {
  description: PropTypes.string.isRequired,
  isPageExcluded: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
};

export default HTML;
