import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Pause from '../../../../assets/player-pause.inline.svg';
import Play from '../../../../assets/player-play.inline.svg';
import styles from './ActionButton.module.less'

const iconProps = {
  className: styles.actionButton__icon,
  width: 24,
};

const ActionButton = ({ isHovered, isInitiated, isPlaying, onClick }) => (
  <button
    className={classnames(styles.actionButton, {
      [styles.actionButtonIsInitiated]: isInitiated,
      [styles.actionButtonIsHovered]: isInitiated && isHovered,
    })}
    onClick={onClick}
    type="button">
    {isPlaying ?
      <Pause {...iconProps} /> :
      <Play {...iconProps} />
    }
  </button>
);

ActionButton.propTypes = {
  isHovered: PropTypes.bool.isRequired,
  isInitiated: PropTypes.bool.isRequired,
  isPlaying: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default ActionButton;
