import React, { PureComponent } from 'react';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import styles from './Sections.module.less';

class Sections extends PureComponent {
  render() {
    const { activeId, baseHref, data, onActiveSectionChange } = this.props;

    return (
      <ul className={styles.tableOfContentSections}>
        {data.map(({ name, id}) => (
          <li className={styles.tableOfContentSections__element} key={id}>
            <Link
              className={classnames(styles.tableOfContentSections__link, {
                [styles.tableOfContentSections__linkIsActive]: activeId === id,
              })}
              title={name}
              to={`${baseHref}#${id}`}
              onClick={(event) => onActiveSectionChange(event, id)}>
              {name}
            </Link>
          </li>
        ))}
      </ul>
    );
  }
}

export default Sections;

Sections.propTypes = {
  activeId: PropTypes.string,
  baseHref: PropTypes.string.isRequired,
};

Sections.defaultProps = {
  activeId: 'getting-started',
};
