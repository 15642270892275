import React from 'react';
import PropTypes from 'prop-types';
import styles from './ImageType.module.less';
import getSrcset from "../../../utils/getSrcset";
import MediaCaption from "../MediaCaption";

const ImageType = ({ data }) => {
  return (
    <figure className={styles.imageType}>
      <img
        className={styles.imageType__media}
        src={data.primary.image.url}
        srcSet={getSrcset(data.primary.image)}
        alt="UXPin dashboard"
      />
      {data.primary.image_description.text && (
        <MediaCaption content={data.primary.image_description.text} />
      )}
    </figure>
  );
};

ImageType.propTypes = {
  data: PropTypes.object.isRequired,
};

export default ImageType;
