import React, { PureComponent} from 'react';
import classnames from 'classnames';
import Container from '../Container';
import Logo from '../Logo';
import Nav from './Nav';
import styles from './TopBar.module.less';
import { LOGO_APPEARANCES } from '../../enums/logo';

class TopBar extends PureComponent {
  state = {
    isMobileNavOpen: false,
  };

  getClassnames = () => {
    return classnames(styles.topBar__logo, { [styles.topBar__logoIsNavOpen]: this.state.isMobileNavOpen });
  };

  handleMobileNav = () => {
    this.setState({ isMobileNavOpen: !this.state.isMobileNavOpen });
  };

  render() {
    const { isMobileNavOpen } = this.state;

    return (
      <div className={styles.topBar}>
        <Container
          inlineStyle
          verticalCenterStyle>
          <Logo
            appearance={LOGO_APPEARANCES.DARK}
            modifierClassname={this.getClassnames()}
          />
          <Nav onMobileNavOpen={this.handleMobileNav} isOpen={isMobileNavOpen} />
        </Container>
      </div>
    );
  }
}

export default TopBar;
