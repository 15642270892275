import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { StaticQuery, graphql } from 'gatsby';
import HTML from './HTML';
import Facebook from "./Facebook";
import Twitter from "./Twitter";


const SEO = ({
  description,
  facebookAppId,
  imageUrl,
  isPageExcluded,
  pageTitle,
  pageUrl,
  title,
  twitterUsername,
}) => {
  return (
    <Fragment>
      <HTML
        description={description}
        isPageExcluded={isPageExcluded}
        title={pageTitle}
      />
      <Facebook
        description={description}
        id={facebookAppId}
        imageUrl={imageUrl}
        pageUrl={pageUrl}
        title={title}
      />
      <Twitter
        description={description}
        imageUrl={imageUrl}
        title={title}
        twitterUsername={twitterUsername}
      />
    </Fragment>
  );
};

SEO.propTypes = {
  description: PropTypes.string.isRequired,
  facebookAppId: PropTypes.string.isRequired,
  isPageExcluded: PropTypes.bool.isRequired,
  pageTitle: PropTypes.string.isRequired,
  pageUrl: PropTypes.string,
  title: PropTypes.string.isRequired,
};

SEO.defaultProps = {
  pageUrl: '',
};

export default ({ description, image, isPageExcluded, pageTitle, pageUrl, title }) => (
  <StaticQuery
    query={graphql`
      query SEO {
        site {
          siteMetadata {
            defaultTitle: title
            defaultDescription: description
            defaultImage: image
            twitterUsername
            facebookAppId
          }
        }
      }
    `}
    render={({
      site: {
        siteMetadata: {
          defaultDescription,
          defaultImage,
          defaultTitle,
          facebookAppId,
          twitterUsername,
        },
      }
    }) => (
      <SEO
        description={description || defaultDescription}
        facebookAppId={facebookAppId}
        imageUrl={image || defaultImage}
        isPageExcluded={isPageExcluded}
        pageTitle={pageTitle || defaultTitle}
        pageUrl={pageUrl}
        title={title || defaultTitle}
        twitterUsername={twitterUsername}
      />
    )}
  />
);
