import React, { forwardRef, PureComponent } from 'react';
import PropTypes from 'prop-types';
import Field from './Field';
import Results from './Results';
import styles from './Popup.module.less';

class Popup extends PureComponent {
  static propTypes = {
    activePage: PropTypes.string.isRequired,
    data: PropTypes.array.isRequired,
    isFieldContainingText: PropTypes.bool.isRequired,
    onSearchDestroy: PropTypes.func.isRequired,
    onFieldChange: PropTypes.func.isRequired,
  };

  componentDidMount() {
    document.body.classList.add(styles.lockWindowScroll);
  }

  componentWillUnmount() {
    document.body.classList.remove(styles.lockWindowScroll);
  }

  render() {
    const {
      activePage,
      data,
      innerRef,
      isFieldContainingText,
      onFieldChange,
      onResultClick
    } = this.props;
    const isSingleView = !data.length && !isFieldContainingText;

    return (
      <div className={styles.popup} ref={innerRef}>
        <Field isSingleView={isSingleView} onChange={onFieldChange} />
        <Results
          activePage={activePage}
          data={data}
          isFieldContainingText={isFieldContainingText}
          onResultClick={onResultClick}
        />
      </div>
    );
  }
}

export default forwardRef((props, ref) => (
  <Popup {...props} innerRef={ref} />
));
