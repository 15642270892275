import React, { Fragment } from 'react';
import classnames from "classnames";
import styles from "./Nav.module.less";

const MobileNav = ({ data }) => {
  return (
    <Fragment>
      <ul className={styles.footerNav__list}>
        {data.map(({ bottom_navigation_link: { url: href }, bottom_navigation_icon: { url: icon }, name }) => !icon && (
          <li
            className={styles.footerNav__item}
            key={name}
          >
            <a
              className={styles.footerNav__link}
              href={href}
              target="_blank">
              {name}
            </a>
          </li>
        ))}
      </ul>
      <ul className={styles.footerNav__list}>
        {data.map(({ bottom_navigation_link: { url: href }, bottom_navigation_icon: { url: icon }, name }) => icon && (
          <li
            className={classnames(styles.footerNav__item, styles.footerNav__itemIcon)}
            key={name}
          >
            <a
              className={styles.footerNav__link}
              href={href}
              target="_blank">
              <img
                alt="Nav icon"
                className={styles.footerNav__icon}
                src={icon}
                title={name}
              />
            </a>
          </li>
        ))}
      </ul>
    </Fragment>
  );
};

export default MobileNav;
