import React from 'react';
import PaginationLink from './PaginationLink';
import { PAGINATION_TYPES } from '../../enums/enums';
import styles from './Pagination.module.less';

const Pagination = (({ previousPage, nextPage }) => {
  return (
    <aside className={styles.pagination}>
      {previousPage && (
        <PaginationLink
          link={previousPage.link}
          type={PAGINATION_TYPES.PREVIOUS}>
          {previousPage.name}
        </PaginationLink>
      )}
      {nextPage && (
        <PaginationLink
          link={nextPage.link}
          type={PAGINATION_TYPES.NEXT}>
          {nextPage.name}
        </PaginationLink>
      )}
    </aside>
  );
});

export default Pagination;
