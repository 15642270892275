const parsePageContent = (pageContent, tableOfContent, activeCategory, activePage) => {
  const activePageIndex = tableOfContent.findIndex(({ page: { uid }}) => uid === activePage);
  const previousPage = tableOfContent[activePageIndex - 1];
  const nextPage = tableOfContent[activePageIndex + 1];

  return {
    ...pageContent,
    nextPage: nextPage && {
      link: `${activeCategory}/${nextPage.page.uid}/`,
      name: nextPage.page.document[0].data.title.text,
    },
    previousPage: previousPage && {
      link: `${activeCategory}/${previousPage.page.uid}/`,
      name: previousPage.page.document[0].data.title.text,
    },
  };
};

export default parsePageContent;
