import classnames from 'classnames';
import { toLower, upperFirst } from 'lodash';
import { KEY_NAMES, KEY_NAMES_TO_CLASS } from '../enums/enums';

const getKeyClassnames = (key) => {
  const keyClassname = 'key';
  const modifiedKey = toLower(key);

  if (!Object.keys(KEY_NAMES_TO_CLASS).includes(modifiedKey)) {
    return;
  }

  return classnames(keyClassname, `${keyClassname}--${KEY_NAMES_TO_CLASS[modifiedKey]}`);
};

const handleShortcut = (regexp, substr) => {
  let modifiedSubstr = toLower(substr);

  if (modifiedSubstr === KEY_NAMES.CMD) {
    modifiedSubstr = KEY_NAMES.CONTROL;
  } else if (modifiedSubstr === KEY_NAMES.OPTION) {
    modifiedSubstr = KEY_NAMES.ALT;
  }

  const keyClassnames = getKeyClassnames(modifiedSubstr);
  const quoteClassnames = classnames('quote', keyClassnames);
  const copy = upperFirst(modifiedSubstr);

  return (
    `<q class="${quoteClassnames}">
      ${keyClassnames ? 
        `<span class="invisible-helper">${copy}</span>` :
        `<span>"</span>${copy}<span>"</span>`}
    </q>`
  );
};

const parseContentType = (html) => {
  const codeRegex = /\`([^\`].*?)\`/g;
  const shortcutRegex = /\&quot;([^].*?)\&quot;/g;
  const appActionRegex = /\~([^\~].*?)\~/g;

  return html.replace(codeRegex, (regexp, substr) => `<code class="code">${substr}</code>`)
    .replace(shortcutRegex, handleShortcut)
    .replace(appActionRegex, (regexp, substr) => `<span class="app-action">${substr}</span>`);
};

export default parseContentType;
