import React from 'react';
import { kebabCase } from 'lodash';
import styles from './LinkListType.module.less'
import { BASE_URL } from "../../../enums/enums";

const getAnchor = (anchor) => anchor && `#${anchor}` || '';

const getUrl = (category, anchor, url) => {
  if (!category.document.length) {
    return url;
  }

  return `/${BASE_URL}/${category.document[0].uid}${url}${getAnchor(anchor)}`
};

const LinkListType = ({ data: { items }}) => (
  <ul className={styles.linkListType}>
    {items.map(({ anchor, category, name, link: { url, target } }) => (
      <li className={styles.linkListType__item} key={kebabCase(name)}>
        <a href={getUrl(category, anchor, url)} target={target}>{name}</a>
      </li>
    ))}
  </ul>
);

export default LinkListType;
