import React, { createRef, Fragment, PureComponent } from 'react';
import PropTypes from 'prop-types';
import { graphql, StaticQuery } from 'gatsby';
import { get, isFunction } from 'lodash';
import Fuse from 'fuse.js';
import { getSearchData } from '../../utils/getSearchData';
import Trigger from './Trigger';
import Popup from './Popup';
import { getSortedResultsByScore, getSortedResultsByTitle } from '../../utils/search';
import { KEY_DOWN, MOUSE_DOWN } from '../../enums/events';
import { ESCAPE } from '../../enums/keys';

const FUSE_SETTINGS = {
  distance: 9999999,
  findAllMatches: true,
  includeScore: true,
  keys: [
    {
      name: 'page',
      weight: 1,
    },
    {
      name: 'title',
      weight: 0.95,
    },
    {
      name: 'content',
      weight: 0.1,
    },
  ],
  location: 0,
  maxPatternLength: 100,
  minMatchCharLength: 1,
  shouldSort: true,
  threshold: 0.1,
};

class Search extends PureComponent {
  constructor(props) {
    super(props);

    this.fuse = null;
    this.state = {
      isFieldContainingText: false,
      isTriggerActive: false,
      searchData: [],
    };
    this.popupElement = createRef();
    this.triggerElement = createRef();
  }

  static propTypes = {
    activePage: PropTypes.string.isRequired,
    onResultClick: PropTypes.func.isRequired,
    onSearchTrigger: PropTypes.func.isRequired,
    searchResultsLimit: PropTypes.number,
  };

  static defaultProps = {
    searchResultsLimit: 10,
  };

  componentDidMount() {
    const { searchData } = this.props;

    this.fuse = new Fuse(searchData, FUSE_SETTINGS);
  }

  componentWillUnmount() {
    this.removeEventListeners();
  }

  addEventListeners = () => {
    window.addEventListener(MOUSE_DOWN, this.handleOutsideSearchClick);
    window.addEventListener(KEY_DOWN, this.handleSearchByKey);
  };

  removeEventListeners = () => {
    window.removeEventListener(MOUSE_DOWN, this.handleOutsideSearchClick);
    window.removeEventListener(KEY_DOWN, this.handleSearchByKey);
  };

  handleOutsideSearchClick = (event) => {
    if (!event) {
      return;
    }

    if (!this.popupElement.current.contains(event.target) &&
      !this.triggerElement.current.contains(event.target)) {
      this.setState({
        isFieldContainingText: false,
      }, this.handleSearchDestroy);
    }
  };

  handleSearchByKey = (event) => {
    if (event && event.key === ESCAPE) {
      this.handleSearchDestroy();
    }
  };

  handleSearch = (event) => {
    if (!event) {
      return;
    }

    const { value } = event.target;

    this.setState({
      isFieldContainingText: value.length > 0,
      searchData: this.getSearchResults(value),
    });
  };

  getSearchResults = (value) => {
    const { searchResultsLimit } = this.props;

    return this.fuse.search(value)
      .map(getSortedResultsByTitle(value))
      .sort(getSortedResultsByScore)
      .splice(0, searchResultsLimit);
  };

  handleTriggerClick = () => {
    const { onSearchTrigger } = this.props;

    this.setState((state) => ({
      isTriggerActive: !state.isTriggerActive,
      searchData: state.isTriggerActive ? [] : state.searchData,
    }), () => {
      const { isTriggerActive } = this.state;

      if (isTriggerActive) {
        this.addEventListeners();
      } else {
        this.removeEventListeners();
      }

      onSearchTrigger();
    });
  };

  handleSearchDestroy = (callback) => {
    const { onSearchTrigger } = this.props;

    this.setState({
      isFieldContainingText: false,
      isTriggerActive: false,
      searchData: [],
    }, () => {
      this.removeEventListeners();
      onSearchTrigger();

      if (isFunction(callback)) {
        callback();
      }
    });
  };

  handleResultClick = (pageId, sectionId) => {
    const { onResultClick } = this.props;

    this.handleSearchDestroy(() => onResultClick(pageId, sectionId));
  };

  render() {
    const { activePage } = this.props;
    const { isFieldContainingText, isTriggerActive, searchData } = this.state;

    return (
      <Fragment>
        <Trigger
          isActive={isTriggerActive}
          onClick={this.handleTriggerClick}
          ref={this.triggerElement}
        />
        {isTriggerActive && (
          <Popup
            activePage={activePage}
            data={searchData}
            isActive={isTriggerActive}
            isFieldContainingText={isFieldContainingText}
            onFieldChange={this.handleSearch}
            onResultClick={this.handleResultClick}
            onSearchDestroy={this.handleSearchDestroy}
            ref={this.popupElement}
          />
        )}
      </Fragment>
    );
  }
}

export default (props) => (
  <StaticQuery
    query={graphql`
      {
        allPrismicDocs {
          edges {
            node {
              data {
                categories {
                  category {
                    uid
                    document {
                      data {
                        name {
                          text
                        }
                        pages {
                          page {
                            uid
                            document {
                              data {
                                title {
                                  text
                                }
                                body {
                                  ... on PrismicDocsPageBodyContent {
                                    slice_type
                                    primary {
                                      content_title {
                                        text
                                      }
                                      content {
                                        text
                                      }
                                    }
                                  }
                                  ... on PrismicDocsPageBodyTip {
                                    slice_type
                                    primary {
                                      tip_title
                                      tip_type
                                      tip {
                                        text
                                      }
                                    }
                                  }
                                  ... on PrismicDocsPageBodyLinkList {
                                    slice_type
                                    items {
                                      name
                                    }
                                  }
                                  ... on PrismicDocsPageBodyTable {
                                    slice_type
                                    items {
                                      col1 {
                                        text
                                      }
                                      col2 {
                                        text
                                      }
                                    }
                                  }
                                }
                                visible_in_table_of_content
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    `}
    render={(data) => (
      <Search
        activePage={props.activePage}
        categories={get(data, 'allPrismicDocs.edges[0].node.data.categories', [])}
        onResultClick={props.onResultClick}
        onSearchTrigger={props.onSearchTrigger}
        searchData={getSearchData(data)}
      />
    )}
  />
);
