import { SLICES } from '../enums/enums';
import { getPageSectionId } from './getPageSectionId';

const getPageSectionsName = (data) => data.filter((section) => {
  return section.slice_type === SLICES.CONTENT && section.primary.content_title.text;
}).map((section) => {
  const name = section.primary.content_title.text;

  return {
    name,
    id: getPageSectionId(name),
  }
});

export default getPageSectionsName;
