import React from 'react';
import { get } from 'lodash';
import ContentType from './types/ContentType';
import VideoType from './types/VideoType/VideoType';
import ImageType from './types/ImageType';
import QuoteType from './types/QuoteType';
import CodeType from './types/CodeType';
import TableType from './types/TableType';
import LinkListType from './types/LinkListType';
import TipType from './types/TipType';
import { SLICES } from '../../enums/enums';

const slicesByType = {
  [SLICES.CODE_TYPE]: CodeType,
  [SLICES.CONTENT]: ContentType,
  [SLICES.IMAGE]: ImageType,
  [SLICES.LINK_LIST]: LinkListType,
  [SLICES.TABLE]: TableType,
  [SLICES.TIP]: TipType,
  [SLICES.VIDEO]: VideoType,
  [SLICES.QUOTE]: QuoteType,
};

const Slices = ({ activeVideo, data, onSubtitleInit }) => data.map((content, index) => {
  const sliceType = content.slice_type;

  if (!sliceType) {
    return null;
  }

  const Slice = get(slicesByType, sliceType);

  return Slice && (
    <Slice
      activeVideo={activeVideo}
      data={content}
      key={`${sliceType}-${index}`}
      onSubtitleInit={onSubtitleInit}
    />
  );
});

export default Slices;
