const getSrcset = (imgData) => {
  let srcset = `${imgData.url} 1x`;

  if (imgData.Retina__2x_) {
    srcset = `${srcset}, ${imgData.Retina__2x_.url} 2x`;
  }

  return srcset;
};

export default getSrcset;
