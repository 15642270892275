import React, { PureComponent } from 'react';
import classnames from "classnames";
import styles from "./NavSwitcher.module.less";
import Close from "../../assets/close.inline.svg";

class NavSwitcher extends PureComponent {

  handleClick = () => {
    this.props.onMobileNavOpen();
  };

  render() {
    const { isActive } = this.props;

    return (
      <button
        className={classnames(styles.navSwitcher, {
          [styles.navSwitcherIsActive]: isActive,
        })}
        onClick={this.handleClick}
        type="button">
        <span className={classnames(styles.navSwitcher__label, {
          [styles.navSwitcher__labelIsActive]: isActive,
        })}>
          {`${isActive ? 'Close' : 'Open'} menu`}
        </span>
      </button>
    );
  }
}

export default NavSwitcher;
