const SLICE_TYPES = {
  CONTENT: 'content',
  LINK_LIST: 'link_list',
  TABLE: 'table',
  TIP: 'tip',
};

module.exports = {
  SLICE_TYPES,
};
