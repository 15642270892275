import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import Pagination from './Pagination';
import Slices from './Slices';
import styles from './Content.module.less';
import useMixpanelTrackDownloads from '../../hooks/useMixpanelTrackDownloads';

const Content = ({ activeVideo, data, onSubtitleInit }) => {
  const articleRef = useRef();

  useMixpanelTrackDownloads(articleRef);

  return (
    <article className={styles.documentationContent} ref={articleRef}>
      <h1 className={styles.documentationContent__title}>{data.title.text}</h1>
      {data.body && (
        <Slices
          activeVideo={activeVideo}
          data={data.body}
          onSubtitleInit={onSubtitleInit}
        />
      )}
      <Pagination
        nextPage={data.nextPage}
        previousPage={data.previousPage}
      />
    </article>
  );
};

Content.propTypes = {
  data: PropTypes.shape({
    data: PropTypes.shape({
      title: PropTypes.shape({
        text: PropTypes.string,
      }),
      content: PropTypes.shape({
        html: PropTypes.node,
      }),
    }),
  }),
};

export default Content;
