import React from 'react';
import parse from 'html-react-parser';
import classnames from 'classnames';
import { TIP_TYPES } from '../../../enums/enums';
import styles from './TipType.module.less';

const TipType = ({ data: { primary: { tip: { html: tip }, tip_title, tip_type } } }) => {
  return (
    <div className={classnames(styles.tipType, {
      [styles.tipTypePowerStyle]: tip_type === TIP_TYPES.POWER,
    })}>
      <div className={styles.tipType__container}>
        {tip_title && (<h5 className={styles.tipType__title}>{tip_title}</h5>)}
        {parse(tip)}
      </div>
    </div>
  );
};

export default TipType;
