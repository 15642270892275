import React from 'react';
import { toLower } from 'lodash';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { atomOneLight } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import styles from './CodeType.module.less';

const CodeType = ({ data: { primary: data } }) => {
  return (
    <div className={styles.codeType}>
      <SyntaxHighlighter language={toLower(data.language)} style={atomOneLight}>
        {data.code.raw[0].text}
      </SyntaxHighlighter>
    </div>
  );
};

export default CodeType;
