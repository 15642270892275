import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Intercom from 'react-intercom';
import Footer from './Footer/Footer';
import TopBar from './TopBar/TopBar';
import { sendVirtualPageView, kissEvent } from '../utils/analytics';
import { INTERCOM_APP_ID } from '../enums/intercom';
import 'normalize.css';
import styles from './Layout.module.less';
import getMixpanel from '../utils/getMixpanel';

class Layout extends PureComponent {
  static propTypes = {
    children: PropTypes.node.isRequired,
    metaDescription: PropTypes.string.isRequired,
    metaImage: PropTypes.string.isRequired,
    metaTitle: PropTypes.string.isRequired,
    pageTitle: PropTypes.string.isRequired,
  };

  componentDidMount() {
    const { pageTitle } = this.props;
    const url = window.location.href;

    getMixpanel((mixpanel) => {
      mixpanel.people.set_once({
        first_visit_url: url,
      });
      mixpanel.track('page_viewed', {
        page_url: url,
        page_title: pageTitle,
      });
    });

    kissEvent('Visited page', {
      'Visited page: Page title': pageTitle,
      'Visited page: Page url': url,
      'Visited page: Page type': 'Documentation',
    });
    sendVirtualPageView({ title: pageTitle, url });
  }

  render() {
    const { children } = this.props;

    return (
      <>
        <Intercom appID={INTERCOM_APP_ID} hide_default_launcher />
        <div id="app" className={classnames(styles.app)}>
          <TopBar />
          {children}
          <Footer />
        </div>
      </>
    );
  }
}

export default Layout;
