import React, { Fragment, PureComponent } from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/Layout';
import Categories from '../components/Categories';
import Documentation from '../components/Documentation';
import getPageSectionsName from '../utils/getPageSectionsName';
import parsePageContent from '../utils/parsePageContent';
import HeadSettings from '../components/Helmet/HeadSettings';
import SEO from '../components/Helmet/SEO';
import getUXPinLink from '../utils/getUXPinLink';

const INITIAL_SEARCH_DATA = {
  pageId: null,
  sectionId: null,
};

export default class Page extends PureComponent {
  state = {
    isSearchOpened: false,
    searchData: INITIAL_SEARCH_DATA,
  };

  handleSearchTrigger = () => {
    this.setState((state) => ({
      isSearchOpened: !state.isSearchOpened,
    }));
  };

  handleResultClick = (pageId, sectionId) => {
    this.setState({
      isSearchOpened: false,
      searchData: {
        pageId,
        sectionId,
      },
    });
  };

  handleSectionChange = () => {
    this.setState({
      searchData: INITIAL_SEARCH_DATA,
    });
  };

  render() {
    const {
      data: {
        prismicDocsCategory: {
          data: {
            pages: tableOfContentData
          }
        },
        prismicDocsPage: {
          data: pageContent,
        }
      },
      pathContext: {
        categoryUid,
        pageUid,
        meta_description,
        meta_image,
        meta_title,
        page_title,
      }
    } = this.props;
    const { isSearchOpened, searchData } = this.state;
    const pageUrl = categoryUid && pageUid && getUXPinLink(`docs/${categoryUid}/${pageUid}/`) || null;

    return (
      <Fragment>
        <HeadSettings
          pageUrl={pageUrl}
        />
        <SEO
          description={meta_description}
          image={meta_image}
          isPageExcluded={pageContent.isVisibleForRobots === 'false'}
          pageTitle={page_title}
          pageUrl={pageUrl}
          title={meta_title}
        />
        <Layout
          metaDescription={meta_description}
          metaImage={meta_image}
          metaTitle={meta_title}
          pageTitle={page_title}>
          <Categories
            activeCategory={categoryUid}
            activePage={pageUid}
            onResultClick={this.handleResultClick}
            onSearchTrigger={this.handleSearchTrigger}
          />
          <Documentation
            activeCategory={categoryUid}
            activePage={pageUid}
            isSearchOpened={isSearchOpened}
            onSectionChange={this.handleSectionChange}
            pageContent={parsePageContent(pageContent, tableOfContentData, categoryUid, pageUid)}
            searchData={searchData}
            subcategories={pageContent.body && getPageSectionsName(pageContent.body) || []}
            tableOfContentData={tableOfContentData}
          />
        </Layout>
      </Fragment>
    );
  }
}

export const pageQuery = graphql`
  query($categoryUid: [String], $pageUid: [String]) {
    prismicDocsCategory(uid: {
      in: $categoryUid
    }) {
      data {
        pages {
          page {
            uid,
            document {
              data {
                title {
                  text
                }
                visible_in_table_of_content
              }
            }
          }
        }
      }
    },
    prismicDocsPage(uid: {
      in: $pageUid
    }) {
      data {
        title {
          text
        },
        isVisibleForRobots: visible_in_table_of_content
        body {
          ... on PrismicDocsPageBodyContent {
            slice_type
            primary {
              content_title {
                text
              }
              content {
                html
              }
            }
          }
          ... on PrismicDocsPageBodyCode {
            slice_type
            primary {
              code {
                raw {
                  text
                }
              }
              language
            }
          }
          ... on PrismicDocsPageBodyTable {
            slice_type
            primary {
              table_first_title {
                text
              }
              table_second_title {
                text
              }
            }
            items {
              col1 {
                html
              }
              col2 {
                html
              }
            }
          }
          ... on PrismicDocsPageBodyVideo {
            slice_type
            primary {
              video_link {
                url
              }
              video_description {
                text
              }
            }
          }
          ... on PrismicDocsPageBodyImageWithCaption {
            slice_type
            primary {
              image {
                url
                Retina__2x_ {
                  url
                }
              }
              image_description {
                text
              }
            }
          }
          ... on PrismicDocsPageBodyQuote {
            slice_type
            primary {
              quote {
                html
              }
              name_of_the_author {
                html
              }
            }
          }
          ... on PrismicDocsPageBodyLinkList {
            slice_type
            items {
              name
              link {
                url
              }
              category {
                document {
                  uid
                }
              }
              anchor
            }
          }
          ... on PrismicDocsPageBodyTip {
            slice_type
            primary {
              tip_type
              tip_title
              tip {
                html
              }
            }
          }
        }
      }
    }
  }
`;
