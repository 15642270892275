const ADDITIONAL_RESULT_SCORE = {
  SINGLE_SECTION_TITLE_WITH_CASE_SENSITIVE: 4,
  SINGLE_SECTION_TITLE_WITHOUT_CASE_SENSITIVE: 3,
  SECTION_TITLE_WITH_CASE_SENSITIVE: 2,
  SECTION_TITLE_WITHOUT_CASE_SENSITIVE: 1,
};

export const getSortedResultsByScore = (a, b) => {
  if (a.score > b.score) {
    return -1;
  }

  if (b.score > a.score) {
    return 1;
  }

  return 0;
};

export const getSortedResultsByTitle = (value) => (result) => {
  const { item: { page, title }, score } = result;
  const isSingleSectionPage = page === title;

  if (isSingleSectionPage) {
    if (page.includes(value)) {
      return {
        ...result,
        score: score + ADDITIONAL_RESULT_SCORE.SINGLE_SECTION_TITLE_WITH_CASE_SENSITIVE,
      };
    }

    if (page.toLowerCase().includes(value)) {
      return {
        ...result,
        score: score + ADDITIONAL_RESULT_SCORE.SINGLE_SECTION_TITLE_WITHOUT_CASE_SENSITIVE,
      };
    }
  }

  if (title.includes(value)) {
    return {
      ...result,
      score: score + ADDITIONAL_RESULT_SCORE.SECTION_TITLE_WITH_CASE_SENSITIVE,
    };
  }

  if (title.toLowerCase().includes(value)) {
    return {
      ...result,
      score: score + ADDITIONAL_RESULT_SCORE.SECTION_TITLE_WITHOUT_CASE_SENSITIVE,
    };
  }

  return result;
};
