function getOnlyContent(regexp, substr) {
  return substr;
}

function cleanUpContentFromSpecialSigns(content) {
  const codeRegex = /\`([^\`].*?)\`/g;
  const shortcutRegex = /\&quot;([^].*?)\&quot;/g;
  const appActionRegex = /\~([^\~].*?)\~/g;

  return content.replace(codeRegex, getOnlyContent)
    .replace(shortcutRegex, getOnlyContent)
    .replace(appActionRegex, getOnlyContent);
}

module.exports = {
  cleanUpContentFromSpecialSigns,
};
