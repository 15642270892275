import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import classnames from 'classnames';
import MediaQuery from 'react-responsive';
import BREAKPOINTS from '../enums/breakpoints';
import styles from './Category.module.less';

const Category = ({ children, icon, isActive, href }) => {
  return (
    <li className={styles.category}>
      <Link
        className={classnames(styles.category__link, {
          [styles.category__linkIsActive]: isActive
        })}
        to={`/${href}`}>
        <MediaQuery minWidth={BREAKPOINTS.TABLET_VERTICAL}>
          <img className={styles.category__illustration} src={icon} alt="Category icon" />
        </MediaQuery>
        {children}
      </Link>
    </li>
  );
};

Category.propTypes = {
  children: PropTypes.node.isRequired,
  isActive: PropTypes.bool.isRequired,
};

export default Category;
