import React, { Fragment, PureComponent } from 'react';
import { Link, navigate } from 'gatsby';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import styles from './Result.module.less';

class Result extends PureComponent {
  static propTypes = {
    activePage: PropTypes.string.isRequired,
    categoryName: PropTypes.string.isRequired,
    categoryUid: PropTypes.string.isRequired,
    content: PropTypes.string.isRequired,
    href: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
    pageTitle: PropTypes.string.isRequired,
    pageUid: PropTypes.string.isRequired,
    sectionTitle: PropTypes.string.isRequired,
    titleUid: PropTypes.string,
  };

  static defaultProps = {
    titleUid: '',
  };

  getSubtitle = () => {
    const { categoryName, pageTitle, sectionTitle } = this.props;

    if (pageTitle === sectionTitle) {
      return `in ${categoryName}`;
    }

    return `in ${categoryName} / ${pageTitle}`;
  };

  handleClick = (event) => {
    const { activePage, href, onClick, pageUid, titleUid } = this.props;

    if (event) {
      event.preventDefault();
    }

    if (pageUid !== activePage) {
      navigate(href);
    }

    onClick(pageUid, titleUid);
  };

  getHeadingClassNames = () => {
    const { pageTitle, sectionTitle } = this.props;

    return (
      classnames(styles.result__heading, {
        [styles.result__headingWithSubtitle]: pageTitle !== sectionTitle,
      })
    );
  };

  render() {
    const { content, href, sectionTitle } = this.props;

    return (
      <li className={styles.result}>
        <a
          className={styles.result__link}
          href={href}
          onClick={this.handleClick}>
          <h3 className={this.getHeadingClassNames()}>
            <span className={styles.result__title}>{sectionTitle}</span> <span className={styles.result__subtitle}>{this.getSubtitle()}</span>
          </h3>
          <p className={styles.result__content}>{content}</p>
        </a>
      </li>
    );
  }
}

export default Result;
