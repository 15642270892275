import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';

const Twitter = ({ description, imageUrl, title, twitterUsername }) => (
  <Helmet>
    <meta name="twitter:card" content="summary_large_image" />
    <meta content={title} name="twitter:title" />
    <meta content={description} name="twitter:description" />
    <meta content={twitterUsername} name='twitter:site' />
    <meta content={twitterUsername} name='twitter:creator' />
    <meta content={imageUrl} name="twitter:image" />
  </Helmet>
);

Twitter.propTypes = {
  description: PropTypes.string.isRequired,
  imageUrl: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  twitterUsername: PropTypes.string.isRequired,
};

export default Twitter;
