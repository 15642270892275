import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import styles from './Trigger.module.less';
import Search from '../../assets/search.inline.svg';

const Trigger = forwardRef(({ isActive, onClick }, ref) => (
  <button
    className={classnames(styles.trigger, {
      [styles.triggerIsActive]: isActive,
    })}
    onClick={onClick}
    ref={ref}
    title="Search"
    type="button">
    <Search />
  </button>
));

Trigger.propTypes = {
  isActive: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default Trigger;
