import logoGray from '../assets/logo-gray.svg';
import logoLight from '../assets/logo-light.svg';
import logoDark from '../assets/logo-dark.svg';

const LOGO_APPEARANCES = {
  GRAY: 'gray',
  LIGHT: 'light',
  DARK: 'dark',
};

const LOGO_FILES = {
  [LOGO_APPEARANCES.GRAY]: logoGray,
  [LOGO_APPEARANCES.LIGHT]: logoLight,
  [LOGO_APPEARANCES.DARK]: logoDark,
};

const LOGO_SIZE = 50;

export {
  LOGO_APPEARANCES,
  LOGO_FILES,
  LOGO_SIZE,
};
