import React from 'react';
import { navigate } from 'gatsby';
import { BASE_URL } from '../../enums/enums';
import styles from './OptionList.module.less'

const OptionList = ({ activeCategory, activePage, pages }) => {
  const handleChange = (event) => {
    navigate(event.target.value);
  };

  return (
    <select
      className={styles.optionList}
      id="toc-option-list"
      onChange={handleChange}
      value={`${BASE_URL}/${activeCategory}/${activePage}`}>
      {pages.map(({ page }) => {
        return (
          <option
            key={page.uid}
            value={`${BASE_URL}/${activeCategory}/${page.uid}`}>
            {page.document[0].data.title.text}
          </option>
        );
      })}
    </select>
  );
};

export default OptionList;
