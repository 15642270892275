import React from 'react';
import classnames from 'classnames';
import styles from './Nav.module.less';

const Nav = ({ data }) => (
  <nav className={styles.footerNav}>
    <ul className={styles.footerNav__list}>
      {data.map(({ bottom_navigation_link: { url: href }, bottom_navigation_icon: { url: icon }, name }) => (
        <li
          className={classnames(styles.footerNav__item, {
            [styles.footerNav__itemIcon]: icon,
          })}
          key={name}
        >
          <a
            className={styles.footerNav__link}
            href={href}
            target="_blank">
            {icon ? (
              <img
                alt="Social icon"
                className={styles.footerNav__icon}
                src={icon}
                title={name}
              />
            ) : (
              name
            )}
          </a>
        </li>
      ))}
    </ul>
  </nav>
);

export default Nav;
