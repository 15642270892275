import React, { Fragment } from 'react';
import { StaticQuery, graphql } from 'gatsby';
import PropTypes from 'prop-types';
import Category from './Category';
import Container from './Container';
import Search from './Search/Search';
import { getFormatedDocsData, getFormatedDocsFirstPageData } from '../utils/getFormatedData';
import { BASE_URL } from '../enums/enums';
import styles from './Categories.module.less';

const Categories = ({ activeCategory, activePage, data, onResultClick, onSearchTrigger }) => (
  <Container modifierClassname={styles.categories}>
    <ul className={styles.categories__list}>
      <Fragment>
        <Search
          activePage={activePage}
          onResultClick={onResultClick}
          onSearchTrigger={onSearchTrigger}
        />
        {data.map(({ category: { uid: categoryUid, document } }) => {
          const categoryName = document[0].data.name.text;
          const pageUid = getFormatedDocsFirstPageData(document).page.uid;

          return (
            <Category
              icon={document[0].data.icon.url}
              isActive={categoryUid === activeCategory}
              key={`category-${categoryUid}`}
              href={`${BASE_URL}/${categoryUid}/${pageUid}/`}>
              {categoryName}
            </Category>
          );
        })}
      </ Fragment>
    </ul>
  </Container>
);

Categories.propTypes = {
  activeCategory: PropTypes.string.isRequired,
  activePage: PropTypes.string.isRequired,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      node: PropTypes.shape({
        data: PropTypes.shape({
          category_name: PropTypes.string,
        }),
        uid: PropTypes.string,
      }),
    }),
  ).isRequired,
  onSearchTrigger: PropTypes.func.isRequired,
  onResultClick: PropTypes.func.isRequired,
};

export default (props) => (
  <StaticQuery
    query={graphql`
      {
        allPrismicDocs {
          edges {
            node {
              data {
                categories {
                  category {
                    uid,
                    document {
                      data {
                        name {
                          text
                        }
                        pages {
                          page {
                            uid
                          }
                        }
                        icon {
                          url
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    `}
    render={(data) => {
      const formatedData = getFormatedDocsData(data).categories;
       if (!formatedData.length) {
         return;
       }

      return (
        <Categories {...props} data={formatedData} />
      );
    }}
  />
);
