import React, { useRef } from 'react';
import { StaticQuery, graphql } from 'gatsby';
import MediaQuery from 'react-responsive';
import Container from '../Container';
import Logo from '../Logo';
import Nav from './Nav';
import Sitemap from './Sitemap';
import BREAKPOINTS from '../../enums/breakpoints';
import { LOGO_APPEARANCES } from '../../enums/logo';
import styles from './Footer.module.less';
import MobileNav from "./MobileNav";
import useMixpanelTrackDownloads from '../../hooks/useMixpanelTrackDownloads';

const Footer = ({ data: { bottom_navigation, copyrights, menu } }) => {
  const footerRef = useRef();

  useMixpanelTrackDownloads(footerRef);

  return (
    <footer className={styles.footer} ref={footerRef}>
      <Container modifierClassname={styles.footer__sitemapContainer} inlineStyle>
        <Logo
          appearance={LOGO_APPEARANCES.DARK}
          modifierClassname={styles.footer__logo}
        />
        <Sitemap data={menu} />
      </Container>
      <Container
        inlineStyle
        modifierClassname={styles.footer__legal}>
        <p className={styles.footer__copyright}>{copyrights}</p>
        <MediaQuery minWidth={BREAKPOINTS.TABLET_VERTICAL}>
          {(matches) => {
            if (matches) {
              return (
                <Nav data={bottom_navigation} />
              )
            } else {
              return (
                <MobileNav data={bottom_navigation} />
              )
            }
          }}
        </MediaQuery>
      </Container>
    </footer>
  );
};

export default (props) => (
  <StaticQuery
    query={graphql`
      {
        allPrismicFooter {
          edges {
            node {
              data {
                copyrights
                menu {
                  nav {
                    document {
                      data {
                        name
                        items {
                          name
                          link {
                            target
                            url
                          }
                          type
                        }
                      }
                    }
                  }
                }
                bottom_navigation {
                  bottom_navigation_icon {
                    url
                  }
                  name
                  bottom_navigation_link {
                    url
                  }
                }
              }
            }
          }
        }
      }
    `}
    render={(data) => <Footer {...props} data={data.allPrismicFooter.edges[0].node.data} />}
  />
);

