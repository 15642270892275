import React from 'react';
import Menu from './Menu';
import styles from './Sitemap.module.less';

const Sitemap  = ({ data }) => (
  <nav className={styles.sitemap}>
    {data.map(({ nav: { document: nav } }, index) => (
      <Menu data={nav[0].data} key={index} isOpen={!index} />
    ))}
  </nav>
);

export default Sitemap;
