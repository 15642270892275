import mixpanelLib from 'mixpanel-browser';
import { MIXPANEL_PROJECT_TOKEN } from '../enums/mixpanelProjectToken';

const debug = process.env.NODE_ENV !== 'production';

export default function getMixpanel(callback) {
  // there is no builtin way to know if the library has loaded or not
  // if get_distinct_id fails, it still has to finish loading
  try {
    mixpanelLib.get_distinct_id();
    callback(mixpanelLib);
  } catch (error) {
    // if not initialized yet, call the callback on 'loaded'
    mixpanelLib.init(MIXPANEL_PROJECT_TOKEN, { debug, loaded: callback });
  }
}
