import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import classnames from 'classnames';
import { getPageName } from '../../utils/getFormatedData';
import Sections from './Sections';
import styles from './TableOfContent.module.less';
import { BASE_URL } from '../../enums/enums';

const TableOfContent = ({ activeCategory, activePage, activeSubcategory, data, onActiveSectionChange, subcategories }) => (
  <ul className={styles.tableOfContent}>
    {data.map(({ page: { document, uid } }) => {
      const isActive = activePage === uid;
      const href = `${BASE_URL}/${activeCategory}/${uid}/`;
      const isVisible = document[0].data.visible_in_table_of_content !== 'false';

      if (!isVisible) {
        return null;
      }

      return (
        <li
          className={styles.tableOfContent__element}
          key={`toc-${uid}`}>
          <Link
            className={classnames(styles.tableOfContent__link, {
              [styles.tableOfContent__linkIsActive]: isActive
            })}
            to={href}>
            {getPageName(document)}
          </Link>
          {(isActive && !!subcategories.length) && (
            <Sections
              activeId={activeSubcategory}
              baseHref={href}
              data={subcategories}
              onActiveSectionChange={onActiveSectionChange}
            />
          )}
        </li>
      );
    })}
  </ul>
);

export default TableOfContent;

TableOfContent.propTypes = {
  activeCategory: PropTypes.string.isRequired,
  activePage: PropTypes.string.isRequired,
  data: PropTypes.array.isRequired,
  subcategories: PropTypes.array.isRequired,
};

